<template>
    <section class="login-booking-area mht">
        <div class="container">
            <div class="row">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <div class="login-booking">
                        <ul class="login-tab-list">
                            <li rel="tab-1" :class="{active: activeMenu === 'login'}" @click="activeMenu = 'login'">LOGIN</li>
                            <li rel="tab-2" :class="{active: activeMenu === 'register'}" @click="activeMenu = 'register'">REGISTER</li>
                        </ul>
                        <div class="login-content">
                            <div id="tab-1" class="content-tab" :style="{display: (activeMenu === 'login' ? 'block' : 'none')}">
                                <div class="login-form">
                                    <form method="post" accept-charset="utf-8" @submit.prevent="userLogin()">
                                        <div class="one-half">
                                            <div class="form-email">
                                                <label for="">Email</label>
                                                <input type="text" name="email" id="email" v-model="$v.login.email.$model" :class="[{custErrorBorder: $v.login.email.$error}]">
                                                <div class="custError" v-if="$v.login.email.$error">Field is required</div>
                                            </div>
                                        </div>
                                        <div class="one-half">
                                            <div class="form-password">
                                                <label for="">Password</label>
                                                <input type="password" name="pass" id="pass" v-model="$v.login.password.$model" :class="[{custErrorBorder: $v.login.password.$error}]">
                                                <div class="custError" v-if="$v.login.password.$error">Field is required</div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        
                                        <div class="one-half">
                                            <div class="btn-submit">
                                                <router-link :to="{name: 'forgotPassword'}">Forgot Password ?</router-link>
                                                <button type="submit">LOGIN</button>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="alert alert-danger" v-if="loginError">{{loginError}}</div>
                                    </form>
                                </div>
                            </div>
                            <div id="tab-2" class="content-tab"  :style="{display: (activeMenu === 'register' ? 'block' : 'none')}">
                                <div class="register-form">
                                    <form  method="get" accept-charset="utf-8" @submit.prevent="registerUser()">
                                        <div class="one-half first-name">
                                            <label for="firstname">First Name </label>
                                            <input type="text" name="firstname" id="firstname" v-model.trim="$v.register.regFName.$model" :class="{custErrorBorder: $v.register.regFName.$error}">
                                            <div class="custError" v-if="$v.register.regFName.$error && !$v.register.regFName.required">Field is required</div>
                                            <div class="custError" v-if="!$v.register.regFName.minLength">Name must have at least {{$v.register.regFName.$params.minLength.min}} letters.</div>
                                        </div>
                                        <div class="one-half last-name">
                                            <label for="lastname">Last Name</label>
                                            <input type="text" name="lastname" id="lastname" v-model.trim="$v.register.regLName.$model" :class="{custErrorBorder: $v.register.regLName.$error}">
                                            <div class="custError" v-if="$v.register.regLName.$error && !$v.register.regLName.required">Field is required</div>
                                            <div class="custError" v-if="!$v.register.regLName.minLength">Name must have at least {{$v.register.regLName.$params.minLength.min}} letters.</div>
                                        </div>
                                        <div class="one-half email">
                                            <label for="email">Email</label>
                                            <input type="text" name="email" autocomplete="off" id="email" v-model.trim="$v.register.regEmail.$model" :class="{custErrorBorder: $v.register.regEmail.$error}">
                                            <div class="custError" v-if="$v.register.regEmail.$error && !$v.register.regEmail.required">Field is required</div>
                                            <div class="custError" v-if="!$v.register.regEmail.email">Enter valid email.</div>
                                        </div>
                                        <div class="one-half phone">
                                            <label for="phone">Phone</label>
                                            <input type="text" name="phone" id="phone" v-model.trim="$v.register.regPhone.$model" :class="{custErrorBorder: $v.register.regPhone.$error}">
                                            <div class="custError" v-if="$v.register.regPhone.$error && !$v.register.regPhone.required">Field is required</div>
                                            <div class="custError" v-if="!$v.register.regPhone.numeric">Enter valid number.</div>
                                            <div class="custError" v-if="!$v.register.regPhone.maxLength">Phone must have at most  {{$v.register.regPhone.$params.maxLength.max}} letters.</div>
                                        </div>
                                        <div class="one-half pass">
                                            <label for="pass">Password</label>
                                            <input type="password" name="pass" autocomplete="off" id="pass" v-model.trim="$v.register.regPassword.$model" :class="{custErrorBorder: $v.register.regPassword.$error}">
                                            <div class="custError" v-if="$v.register.regPassword.$error && !$v.register.regPassword.required">Field is required</div>
                                            <div class="custError" v-if="!$v.register.regPassword.minLength">Password must have at least {{$v.register.regPassword.$params.minLength.min}} letters.</div>
                                        </div>
                                        <div class="one-half re-pass">
                                            <label for="re-pass">Repeat Password</label>
                                            <input type="password" name="phone" id="re-pass" v-model.trim="$v.register.regRepeatPassword.$model" :class="{custErrorBorder: $v.register.regRepeatPassword.$error}">
                                            <div class="custError" v-if="!$v.register.regRepeatPassword.sameAsRegPassword">Passwords must be identical.</div>
                                        </div>
                                        <div class="one-half checkbox">
                                            <input type="checkbox" name="accept" id="accept" v-model="$v.register.terms.$model">
                                            <label for="accept">Accept <a href="#" title="">terms & conditions</a> and the <a href="#" title="">privacy policy</a> input</label>
                                            <div class="custError" v-if="!$v.register.terms.checked">Passwords must be identical.</div>
                                        </div>
                                        <div class="one-half btn-submit">
                                            <button type="submit">REGISTER</button>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="alert alert-danger" v-if="registerError">{{registerError}}</div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>
    </section>
</template>

<script>
import { required, minLength, maxLength, sameAs, email, numeric } from 'vuelidate/lib/validators';
import { request } from "../utils/api";
import {mapGetters} from "vuex";

export default {
    data(){
        return {
            activeMenu: 'login',
            loginError: '',
            registerError: '',
            register: {
                regFName: '',
                regLName: '',
                regEmail: '',
                regPassword: '',
                regRepeatPassword: '',
                regPhone: '',
                terms: '',
            },
            login: {
                email: '',
                password: ''
            },

        }
    },
    computed:{
        ...mapGetters(['isLoggedIn'])
    },
    validations: {
        login: {
            email: {
                required
            },
            password: {
                required
            }
        },
        register: {
            regFName: {
                required,
                minLength: minLength(2)
            },
            regLName: {
                required,
                minLength: minLength(2)
            },
            regEmail: {
                required,
                email
            },
            regPhone: {
                required,
                numeric,
                maxLength: maxLength(10)
            },
            regPassword: {
                required,
                minLength: minLength(6)
            },
            regRepeatPassword: {
                sameAsRegPassword: sameAs('regPassword')
            },
            terms: {
                checked: (val) => {return val;},
            }
        }
    },
    created(){
        if(this.isLoggedIn){
            this.$router.push('/');
            return;
        }
    },
    methods:{
        async registerUser(){
            this.$v.register.$touch();
			if (this.$v.register.$anyError) {
                return;
            }

            const resp = await request({
                method: 'post',
                url: `/api/v1/user/register`,
                data: {
                        firstName: this.register.regFName,
                        lastName: this.register.regLName,
                        email: this.register.regEmail,
                        mobileNumber: this.register.regPhone,
                        password: this.register.regPassword
                    }
            })
            if (resp.remote === "success") {
                this.$store.commit('setUser', resp.data);

                if(this.$router.history.current.query.redirectUrl){
                    this.$router.push({name: this.$router.history.current.query.redirectUrl})
                }else{
                    this.$router.push('/');
                }
            }else {
                this.registerError = resp.errors;
            }
        },
        async userLogin(){
            this.$v.login.$touch();
			if (this.$v.login.$anyError) {
                return;
            }

            const resp = await request({
                method: 'post',
                url: `/api/v1/user/login`,
                data: {
                        email: this.login.email,
                        password: this.login.password
                    }
            })
            
            if (resp.remote === "success") {
                this.$store.commit('setUser', resp.data);
                if(this.$router.history.current.query.redirectUrl){
                    this.$router.push({name: this.$router.history.current.query.redirectUrl})
                }else{
                    this.$router.push('/');
                }
            }else{
                this.loginError = resp.errors;
            }
        }
    },
}
</script>

<style>

</style>